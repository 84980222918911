.login{
  width: 100%;
  height: 100vh;
  background: url('../../images/bg.png') no-repeat;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
  position: relative;
}
.form_box{
  position: absolute;
  top: 10vh;
  right: 10%;
  width: 35%;
  border-radius: 10px;
  margin-top: 10vh;
}
.login .ant-btn-primary{
  background: #F6494D;
  border-color: #F6494D;
}
.welcome_text{
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 54px;
}
.subimt_btn{
  width: 72%;
  height: 7vh;
  background: #8291FD !important;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  margin-top: 8vh;
}
.mb80{
  margin-bottom: 8vh;
}
.form_box .ant-input{
  border: none;
  width: 24vw;
  height: 7vh !important;
  border-bottom: 1px solid #9B9B9B;
  border-radius: 0;
  box-shadow: none !important;
}
.form_box  .ant-input-affix-wrapper{
  width: 24vw;
  border: none;
  height: 7.8vh !important;
  border-radius: 0;
  border-bottom: 1px solid #9B9B9B;
  box-shadow: none !important;
}